/**
 * This file is a port of the v-money package.
 *
 * @author Marcos Neves <marcos.neves@gmail.com> (https://vuejs-tips.github.io/)
 * @see https://github.com/vuejs-tips/v-money
 * @license MIT
 * @copyright 2017 Marcos Neves
 */

export interface MoneyOptions {
  prefix: string,
  suffix: string,
  thousands: string,
  decimal: ',' | '.' | string,
  precision: number,
}

const DEFAULT_OPTIONS: MoneyOptions = {
  prefix: '',
  suffix: '',
  thousands: '',
  decimal: '.',
  precision: 2,
};

function assign(defaults: any = {}, extras: any = {}): any {
  return Object.keys(defaults).concat(Object.keys(extras)).reduce((acc: any, val: any) => {
    acc[val] = extras[val] === undefined ? defaults[val] : extras[val];
    return acc;
  }, {});
}

function toStr(input: any): string {
  return input ? input.toString() : '';
}

function format(input: string | number, opt: MoneyOptions = DEFAULT_OPTIONS): string {
  // eslint-disable-next-line no-param-reassign
  if (typeof input === 'number') input = input.toFixed(opt.precision);

  const neg = input.indexOf('-') >= 0 ? '-' : '';
  const nums = toStr(input).replace(/\D+/g, '') || '0';
  const curr = (parseFloat(nums) / (10 ** opt.precision)).toFixed(opt.precision);
  // eslint-disable-next-line prefer-const
  let [int, dec] = curr.toString().split('.');
  int = int.replace(/(\d)(?=(?:\d{3})+\b)/gm, `$1${opt.thousands}`);
  return `${opt.prefix}${neg}${dec ? int + opt.decimal + dec : int}${opt.suffix}`;
}

function unformat(input: string, prec: number): number {
  const neg = input.indexOf('-') >= 0 ? -1 : +1;
  const nums = toStr(input).replace(/\D+/g, '') || '0';
  const curr = (parseFloat(nums) / (10 ** prec)).toFixed(prec);
  return parseFloat(curr) * neg;
}

function setCursor(el: any, pos: number): void {
  if (el === document.activeElement) {
    el.setSelectionRange(pos, pos);
    window.setTimeout(() => {
      el.setSelectionRange(pos, pos);
    }, 1); // Android fix
  }
}

// https://developer.mozilla.org/en-US/docs/Web/Guide/Events/Creating_and_triggering_events#The_old-fashioned_way
function event(name: string): Event {
  const ev = document.createEvent('Event');
  ev.initEvent(name, true, true);
  return ev;
}

export default function (el: any, binding: any): void {
  if (!binding.value) return;

  const opt = assign(DEFAULT_OPTIONS, binding.value);

  if (el.tagName.toLocaleUpperCase() !== 'INPUT') {
    throw new Error('Use this directive only on <input> elements');
  }

  // eslint-disable-next-line no-param-reassign
  el.oninput = (): void => {
    let posFromEnd = el.value.length - el.selectionEnd;
    // eslint-disable-next-line no-param-reassign
    el.value = format(el.value, opt);
    posFromEnd = Math.max(posFromEnd, opt.suffix.length);
    posFromEnd = el.value.length - posFromEnd;
    posFromEnd = Math.max(posFromEnd, opt.prefix.length + 1);
    setCursor(el, posFromEnd);
    el.dispatchEvent(event('change'));
  };

  // eslint-disable-next-line no-param-reassign
  el.onfocus = (): void => {
    setCursor(el, el.value.length - opt.suffix.length);
  };

  el.oninput();
  el.dispatchEvent(event('change'));
}

export {
  format,
  unformat,
};
