

















































import Vue from 'vue';
import money, { format, unformat, MoneyOptions } from '@/directives/money';

function getFormat(formatted: string): MoneyOptions {
  const r = new RegExp(/^(?<prefix>\D*)\d+(?<thousands>\D*)\d+(?<decimal>\D*)\d+(?<suffix>\D*)$/g)
    .exec(formatted);

  if (!r) {
    return {
      precision: 2,
      decimal: '.',
      thousands: '',
      prefix: '',
      suffix: '',
    };
  }

  return {
    precision: 2,
    decimal: r[3],
    thousands: r[2],
    prefix: r[1],
    suffix: r[4],
  };
}

export default Vue.extend({
  directives: {
    money,
  },
  inheritAttrs: false,
  props: {
    // Money Functionality
    value: {
      type: [Number, String],
      required: true,
    },
    moneyCurrency: {
      type: String,
      required: true,
    },
    // precision: {
    //   type: Number,
    //   required: true,
    // },
    // decimal: {
    //   type: String,
    //   required: true,
    // },
    // thousands: {
    //   type: String,
    //   required: true,
    // },
    // prefix: {
    //   type: String,
    //   required: true,
    // },
    // suffix: {
    //   type: String,
    //   required: true,
    // },
    // Input
    fieldId: {
      type: String,
      required: false,
      default: 'field',
    },
    fieldKey: {
      type: String,
      required: false,
      default: 'generic',
    },
    formId: {
      type: String,
      required: false,
      default: 'form',
    },

    // p.control Style
    isExpanded: Boolean,
    isLoading: Boolean,
    // input Style

    hasError: Boolean,
    hasNumbers: Boolean,
    inputClass: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    },
    isFullwidth: Boolean,
    isStatic: Boolean,
  },
  data() {
    return {
      formatted: '',
    };
  },
  computed: {
    moneyPrecision(): number {
      let p;

      switch (this.moneyCurrency) {
        case 'EUR':
        case 'USD':
        default:
          p = 2;
          break;
      }

      return p;
    },
    moneyOptions(): MoneyOptions {
      const formatted = this.$n(
        123456 / this.moneyPrecision,
        this.moneyCurrency.toUpperCase(),
      );

      return getFormat(formatted);
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(to) {
        const formatted = format(to, this.moneyOptions);
        if (formatted !== this.formatted) this.formatted = formatted;
      },
    },
  },
  methods: {
    onChange(event: any) {
      console.debug('InputMoney', 'onChange');
      this.$emit(
        'input',
        unformat(event.target.value, this.moneyOptions.precision),
      );
      (this.$refs.input as HTMLInputElement).setCustomValidity('');
    },
    $v(msg?: string): boolean {
      console.debug('InputMoney', '$v', msg);
      const i = this.$refs.input as HTMLInputElement;

      i.setCustomValidity(msg || '');
      i.reportValidity();

      return i.checkValidity();
    },
  },
});
