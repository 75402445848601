var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',{staticClass:"control",class:[
    {
      'is-loading': _vm.isLoading,
      'is-expanded': _vm.isExpanded,
    }
  ]},[_c('input',_vm._b({directives:[{name:"money",rawName:"v-money",value:(_vm.moneyOptions),expression:"moneyOptions"}],ref:"input",staticClass:"input text-right",class:_vm.inputClass.concat( [{
        'is-danger': _vm.hasError,
        'numeric-tabular-nums': _vm.hasNumbers,
        'is-static': _vm.isStatic,
        'is-fullwidth': _vm.isFullwidth,
      }]
    ),attrs:{"id":_vm.$attrs.id || (_vm.formId + "." + _vm.fieldId + "." + _vm.fieldKey),"form":_vm.formId,"placeholder":_vm.$attrs.placeholder ? _vm.$attrs.placeholder : _vm.$t(("placeholder." + _vm.fieldKey)),"type":"tel","maxlength":(_vm.moneyOptions.prefix.length
      + _vm.moneyOptions.suffix.length
      + _vm.moneyOptions.decimal.length
      + (_vm.moneyOptions.thousands.length * 2)
      + 9 // limits to 9 digits
    ),"size":(_vm.moneyOptions.prefix.length
      + _vm.moneyOptions.suffix.length
      + _vm.moneyOptions.decimal.length
      + (_vm.moneyOptions.thousands.length * 2)
      + 9 // limits to 9 digits
      + 2
    )},domProps:{"value":_vm.formatted},on:{"input":_vm.onChange}},'input',_vm.$attrs,false)),_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }